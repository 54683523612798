.maleFemale input[type="radio"]:checked + .box {
    background-color: cornflowerblue;
    border:black;
    border: solid;
  }
  .maleFemale input[type="radio"]:checked + .box span {
    color: white;
  }
  .maleFemale .box {
    background-color: lightgray;
    display: inline-block;
    cursor: pointer;
  }
  .maleFemale .box span {
    position: absolute;
    padding-top: 25%;
    left: 0;
    right: 0;
    color: #007e90;
  }
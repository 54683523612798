#questions label,
#questions label {
  color: #1a3065;
  font-size: 30px;
  border-radius: 10px;
  background-color: #f1f0ec;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  text-align: center;
  border: 4px solid transparent;
}

#questions input:checked:before,
#questions input:checked + label:before {
  color: #6e98f2;
  font-size: 35px;
  background-color: #f1f0ec;
  border-radius: 999px;
}

#questions input:not(:checked):before,
#questions input:not(:checked) + label:before {
  font-size: 35px;
  color: #1a3065;
}

#questions input:checked + label,
#questions input:checked + label {
  color: white;
  border: 4px solid;
  border-radius: 29px;
  border-color: #1a3065;
}

/* The scrollbar track */
::-webkit-scrollbar {
  width: 20px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #b4adad;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b4adad;
}

/* The scrollbar track */
::-webkit-scrollbar-track {
  background: #e3e0e0;

  border-radius: 10px;
}

/* Hide scrollbar buttons */
::-webkit-scrollbar-button {
  display: none;
}

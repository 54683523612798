@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif, 'rubik';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace, 'rubik';
}

@font-face {
  font-family: 'rubik';
  src: local('rubik'), url(./fonts/Rubik-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'FontAwesome';
  src: local('FontAwesome'), url(./fonts/FontAwesome6Free-Solid-900.otf) format('opentype');
}
